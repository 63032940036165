[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color: #111217;
  --amplify-components-button-color: white;
  --amplify-components-button-primary-border-color: white;
  --amplify-components-field-label-color: white;
  --amplify-components-text-color: white;
  --amplify-components-input-color: white;
  --amplify-components-button-background-color: #111217;
  --amplify-components-input-focus-border-color: white;
  --amplify-components-heading-color: white;
  --amplify-components-button-border-color: grey;
  --amplify-components-authenticator-modal-background-color: #111217;
  --amplify-components-authenticator-router-background-color: #111217;
  --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 1px #111217;
  --amplify-components-tabs-item-active-border-color: white;
  --amplify-components-tabs-item-inactive-border-color: 111217;
  --amplify-components-tabs-item-color: #111217;
  --amplify-components-tabs-item-active-color: #111217;
  --amplify-components-tabs-item-border-color: #111217;
  --amplify-components-button-link-color: white;
  --amplify-components-text-error-color: #d10606;
  --amplify-components-fieldcontrol-error-border-color: #d10606;
}

[data-amplify-authenticator] button:hover {
  background-color: transparent;
  color: white;
  border-color: white;
}


[data-amplify-authenticator] button {
  background-color: transparent;
  color: white;
}

[data-amplify-authenticator] a:hover {
  color: #111217;
}

.super-app-theme--header {
  background-color: white;
}

.MuiPaper-root {
  border-radius: 15px !important;
}

.MuiButton-root {
  box-shadow: none !important;
}